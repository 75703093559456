import React, { Component } from 'react';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import './StyleSheet.scss';

function Date2String(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}
function DateString2StringSQl(date) {
    const d = new Date(date);
    return `${d.getFullYear()}-${(d.getMonth() + 1 >= 10 ? "" : "0")}${d.getMonth() + 1}-${(d.getDate() + 1 >= 10 ? "" : "0")}${d.getDate()}`
}
function DateString2String(date) {
    const d = new Date(date);
    return `${(d.getDate() + 1 >= 10 ? "" : "0")}${d.getDate()}.${(d.getMonth() + 1 >= 10 ? "" : "0")}${d.getMonth() + 1}.${d.getFullYear()}`
}
const rowsPerPage = 20;
export class PhysUserDocs extends Component {


    static displayName = PhysUserDocs.name;


    constructor(props) {
   
        super(props);

        //console.log(params);
        /*        this.state = { forecasts: [], loading: true };*/
        this.state =
        {
            data: [],
            Doc: '1',
            loading: true,
            failedToLoad: false,
            balance: 0,
            activePage: 0,
            IsDocNotFined: false,
            IsDocFinded: false,
            PayWindowActive: false,
            DocDate: "1999-09-09"
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handlePayButton = this.handlePayButton.bind(this);
        this.handleButClick = this.handleButClick.bind(this);
        this.handleArrowClick = this.handleArrowClick.bind(this);
        this.SearchButtonClick = this.SearchButtonClick.bind(this);
        this.CompletePayButton = this.CompletePayButton.bind(this);
        this.handlecancellButtonClick = this.handlecancellButtonClick.bind(this);

        //this.NoneClick = this.NoneClick.bind(this);
    }
    static emailValidation(email) {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!email || email.length < 4 || regex.test(email) === false) {

            return false;
        }
        return true;
    }

    handlePayButton(event) {
        this.setState({ PayWindowActive: true });
        event.preventDefault();

        //this.SberButton();
    }
    CompletePayButton(event) {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            this.setState({ PayWindowActive: false });
            //console.log(event.target.elements);
            //console.log(event.target.elements.summ.value);
            //console.log(event.target.elements.email.value);
            event.preventDefault();
            this.SberButton(event.target.elements.email.value, event.target.elements.summ.value);
        }
    }
    componentDidMount() {
        //this.loadData();
    }
    handlecancellButtonClick(event) {
        event.preventDefault();
        this.setState({ PayWindowActive: false });
    }
    handleInputChange(event) {
        this.setState({
            Doc: event.
                target.
                value.
                replace(/\D/g, '')
        });
    };
    SearchButtonClick(event) {
        event.preventDefault();
        const len = this.state.Doc.length;
        if (len >= 8 && len <= 9)
            this.loadData();
    };

    handleButClick(i, event) {
        event.preventDefault();
        this.setState({ activePage: i });
    }
    handleArrowClick(i, event) {
        event.preventDefault();
        const l = this.state.data.length;
        const d = Math.floor((l - 1) / rowsPerPage);
        let s = this.state.activePage + i;
        if (s > d) s = d;
        if (s < 0) s = 0;
        this.setState({ activePage: s });
    }
    static renderSearchPage(This) {
        return (
            <div className="Doc_search_page">
                <div className="Doc_search_container">
                    <div className="Doc_search_logo">
                        <img src={require('../Images/logo.jpg')} alt="logo.jpg" />
                    </div>
                    <form action="#" className="Doc_search_form">

                        <svg width="32"
                            height="40"
                            viewBox="0 0 21 27"
                            className="Doc_search_icon"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.438 5.656l4.406 4.625c0.438 0.469 0.813 1.344 0.813 2.031v13.688c0 0.625-0.5 1.188-1.188 1.188h-16.281c-0.625 0-1.188-0.563-1.188-1.188v-20.031c0-0.625 0.563-1.188 1.188-1.188h10.25c0.688 0 1.563 0.406 2 0.875zM2.469 25.125h13.719c0.219 0 0.406-0.188 0.406-0.406v-11.438c0-0.219-0.188-0.406-0.406-0.406h-4.906c-0.219 0-0.406-0.188-0.406-0.406v-5.219c0-0.219-0.156-0.375-0.375-0.375h-8.031c-0.219 0-0.375 0.156-0.375 0.375v17.469c0 0.219 0.156 0.406 0.375 0.406zM15.219 10.531l-1.969-2.125c-0.156-0.156-0.281-0.094-0.281 0.125v1.906c0 0.188 0.188 0.375 0.375 0.375h1.75c0.219 0 0.281-0.125 0.125-0.281zM4.875 8.719h3.875c0.406 0 0.781 0.375 0.781 0.813v0.469c0 0.406-0.375 0.781-0.781 0.781h-3.875c-0.438 0-0.813-0.375-0.813-0.781v-0.469c0-0.438 0.375-0.813 0.813-0.813zM4.875 12.906h3.875c0.406 0 0.781 0.375 0.781 0.813v0.469c0 0.406-0.375 0.781-0.781 0.781h-3.875c-0.438 0-0.813-0.375-0.813-0.781v-0.469c0-0.438 0.375-0.813 0.813-0.813zM13.719 19.156h-8.844c-0.438 0-0.813-0.344-0.813-0.781v-0.469c0-0.438 0.375-0.781 0.813-0.781h8.844c0.406 0 0.813 0.344 0.813 0.781v0.469c0 0.438-0.406 0.781-0.813 0.781zM13.719 23.438h-8.844c-0.438 0-0.813-0.344-0.813-0.75v-0.5c0-0.438 0.375-0.781 0.813-0.781h8.844c0.406 0 0.813 0.344 0.813 0.781v0.5c0 0.406-0.406 0.75-0.813 0.75z"></path>
                        </svg>
                        <input
                            name="Doc"
                            type="text"
                            placeholder="Номер договора"
                            className="Doc_search_input"
                            value={This.state.Doc}
                            onChange={This.handleInputChange}
                        />
                        <button className="Doc_search_button" onClick={This.SearchButtonClick}>
                            <svg className="Doc_search_icon2" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.696 18.217l-4.93-4.95a7.884 7.884 0 001.962-5.192C16.728 3.623 12.976 0 8.364 0 3.752 0 0 3.623 0 8.075c0 4.452 3.752 8.075 8.364 8.075a8.48 8.48 0 004.792-1.461l4.967 4.988c.208.208.487.323.787.323.283 0 .552-.104.756-.294.433-.403.447-1.07.03-1.49zM8.364 2.107c3.409 0 6.182 2.677 6.182 5.968s-2.773 5.968-6.182 5.968c-3.409 0-6.182-2.677-6.182-5.968s2.773-5.968 6.182-5.968z" fillRule="evenodd">
                                </path>
                            </svg>
                            <span>Найти</span>
                        </button>

                    </form>
                    {PhysUserDocs.renderNotFoundMessage(This)}
                    <div className="Doc_search_subtext">
                        Чтобы узнать свой баланс и оплатить услуги, введите номер договора
                    </div>
                    <div className="Doc_search_bot">
                        <a href="https://sibtko.ru/" rel="nofollow" className="Doc_search_link">
                            <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 0v2H2v8h8V8h2v4H0V0h4zm8 0v6h-2V3.5L5.5 8.003 4 6.5 8.5 2H6V0h6z" fillRule="evenodd" />
                            </svg>
                            Вернуться на главную
                        </a>
                        <a href="mailto:feedback@sibtko.ru" target="_blank" rel="nofollow" className="Doc_search_link">
                            <svg width="14" height="12" viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 4v6.636c0 .375-.971 1.364-1.34 1.364H1.34C.97 12 0 11.011 0 10.636V4l7 4 7-4zm-2-4c1.333 0 2 .667 2 2v1.003L7 7 0 3.003V2C0 .667.667 0 2 0z" ll-rule="nonzero" />
                            </svg>
                            feedback@sibtko.ru
                        </a>
                        <a href="tel:+78006001420" className="Doc_search_link">
                            <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.993 11.049a.677.677 0 01-.207.593l-1.971 1.957a1.11 1.11 0 01-.349.253c-.143.069-.284.113-.422.133l-.089.008c-.05.004-.113.007-.193.007-.187 0-.491-.032-.911-.096-.42-.065-.934-.223-1.541-.475-.608-.252-1.297-.63-2.068-1.135-.77-.504-1.59-1.196-2.46-2.076a16.248 16.248 0 01-1.72-1.957A13.949 13.949 0 01.967 6.533a9.309 9.309 0 01-.623-1.439c-.138-.43-.232-.8-.281-1.112a3.676 3.676 0 01-.06-.734c.01-.178.015-.277.015-.297.02-.138.065-.279.134-.422.069-.144.153-.26.252-.349L2.373.208A.653.653 0 012.849 0c.129 0 .242.037.34.111.1.074.184.166.253.275l1.586 3.01a.722.722 0 01.074.52.925.925 0 01-.252.474l-.726.727a.247.247 0 00-.052.096.366.366 0 00-.022.111c.04.208.128.445.266.712.119.237.302.526.549.868.247.34.597.734 1.052 1.179.445.454.84.808 1.186 1.06.345.252.634.437.867.556.232.119.41.19.533.215l.185.037c.02 0 .052-.007.097-.022a.247.247 0 00.096-.052l.845-.86a.908.908 0 01.622-.237c.168 0 .302.03.4.089h.015l2.86 1.69c.208.129.332.292.371.49z" ll-rule="evenodd" />
                            </svg>
                            8 800 600 14 20
                        </a>
                    </div>
                </div>
            </div>
        )
    }
    static renderNotFoundMessage(This) {

        if (This.state.IsDocNotFined == true)

            return (
                <div className="Doc_seatch_notfound">
                    <svg width="45" height="40" viewBox="0 0 45 40" xmlns="http://www.w3.org/2000/svg">
                        <g fill-rule="nonzero" fill="none">
                            <path d="M44.177 31.186L27.534 2.845C26.457 1.073 24.584 0 22.5 0c-2.084 0-3.957 1.073-5.034 2.845L.823 31.186c-1.077 1.843-1.1 4.034-.047 5.876C1.83 38.904 3.726 40 5.857 40h33.286c2.13 0 4.028-1.096 5.081-2.938 1.054-1.842 1.03-4.033-.047-5.876z" fill="#475364" /> <path d="M42.01 32.258L25.496 4.186C24.87 3.124 23.73 2.5 22.5 2.5c-1.231 0-2.37.624-2.997 1.686L2.991 32.258a3.412 3.412 0 00-.023 3.487 3.432 3.432 0 003.02 1.755h33.024a3.432 3.432 0 003.02-1.755 3.412 3.412 0 00-.023-3.487z" fill="#FFF500" /> <path d="M22.5 32.5a1.877 1.877 0 01-1.875-1.875c0-1.034.841-1.875 1.875-1.875s1.875.841 1.875 1.875A1.877 1.877 0 0122.5 32.5zM23.75 25c0 .833-.42 1.25-1.26 1.25-.84 0-1.253-.417-1.24-1.25V10c-.013-.833.4-1.25 1.24-1.25s1.26.417 1.26 1.25v15z" fill="#475364" />
                        </g>
                    </svg>
                    <div className="text"><div className="title">
                        Договор не найден
                    </div>
                        Проверьте правильно ли введен номер договора и повторите попытку поиска
                    </div></div>
            )
        else return (<div></div>)

    }
    static renderDocumentPage(This) {
        let contents = This.state.loading
            ? This.state.failedToLoad
                ? <p><em>Failed to load...</em></p>
                : <p><em>Loading...</em></p>
            : PhysUserDocs.renderDocTable(This.state.data, This.state.activePage);
        if (This.state.failedToLoad) This.loadData();
        /*   This.setState({ activePage:0 })*/
        const switchPage = PhysUserDocs.renderPageSwitch(This, This.state.data, This.state.activePage);
        return (<div>
            <header className="Doc_view_header">
                <div className="Doc_view_container">
                    <div className="Doc_view_top"><a href="https://sibtko.ru/" className="b-landing__logo-inner">
                        <img src={require('../Images/logo-inner.png')} alt="" width="100" height="60" />
                    </a>
                        <div className="Doc_view_top_container">
                            <a href="https://sibtko.ru/" className="Doc_view_top_button">
                                <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 0v2H2v8h8V8h2v4H0V0h4zm8 0v6h-2V3.5L5.5 8.003 4 6.5 8.5 2H6V0h6z" fillRule="evenodd" />
                                </svg>
                                Вернуться на главную
                            </a>
                            <a href="mailto:feedback@sibtko.ru" target="_blank" rel="nofollow" className="Doc_view_top_button">
                                <svg width="14" height="12" viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14 4v6.636c0 .375-.971 1.364-1.34 1.364H1.34C.97 12 0 11.011 0 10.636V4l7 4 7-4zm-2-4c1.333 0 2 .667 2 2v1.003L7 7 0 3.003V2C0 .667.667 0 2 0z" fillRule="nonzero" />
                                </svg>
                                feedback@sibtko.ru
                            </a>
                            <a href="tel:+78006001420" className="Doc_view_top_button">
                                <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.993 11.049a.677.677 0 01-.207.593l-1.971 1.957a1.11 1.11 0 01-.349.253c-.143.069-.284.113-.422.133l-.089.008c-.05.004-.113.007-.193.007-.187 0-.491-.032-.911-.096-.42-.065-.934-.223-1.541-.475-.608-.252-1.297-.63-2.068-1.135-.77-.504-1.59-1.196-2.46-2.076a16.248 16.248 0 01-1.72-1.957A13.949 13.949 0 01.967 6.533a9.309 9.309 0 01-.623-1.439c-.138-.43-.232-.8-.281-1.112a3.676 3.676 0 01-.06-.734c.01-.178.015-.277.015-.297.02-.138.065-.279.134-.422.069-.144.153-.26.252-.349L2.373.208A.653.653 0 012.849 0c.129 0 .242.037.34.111.1.074.184.166.253.275l1.586 3.01a.722.722 0 01.074.52.925.925 0 01-.252.474l-.726.727a.247.247 0 00-.052.096.366.366 0 00-.022.111c.04.208.128.445.266.712.119.237.302.526.549.868.247.34.597.734 1.052 1.179.445.454.84.808 1.186 1.06.345.252.634.437.867.556.232.119.41.19.533.215l.185.037c.02 0 .052-.007.097-.022a.247.247 0 00.096-.052l.845-.86a.908.908 0 01.622-.237c.168 0 .302.03.4.089h.015l2.86 1.69c.208.129.332.292.371.49z" fillRule="evenodd" />
                                </svg>
                                8 800 600 14 20
                            </a>
                        </div>
                    </div>
                   
                    <div className="Doc_view_current_doc_label">
                        Просматриваемый договор
                        <div className="Doc_view_current_doc_label_doc">
                            № {This.state.Doc} от {DateString2String(This.state.DocDate)}
                        </div>
                        {
                            !This.state.Dog.isActive ?
                            <span className="Alert_DogClosed">
                                Закрыт
                                </span>
                            :<></>
                        }
                    </div>
                </div>
            </header>
            <div className="Doc_view_body">
                <div className="Doc_view_container">
                    <nav className="Doc_view_middle_info_container">
                        <span>
                            <div className="Decorative_icon" >
                                <svg width="12" height="14" viewBox="0 0 12 14" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 12h2v2h2v-2h5v-2H4V8h4c2.667-.372 4-1.706 4-4.002C12 1.703 10.667.37 8 0H2c-.058 0-.058 1.999 0 5.997H0V8h2v2H0v2zM4 2h3.5c1.667.15 2.5.817 2.5 1.998 0 1.182-.833 1.848-2.5 1.999H4V2z" fillRule="evenodd" />
                                </svg>
                            </div>
                            Начисления и платежи
                        </span>
                        {
                            /*
                            <a href="objects/">
                                <div className="Decorative_icon" >
                                    <svg width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M18 9l-3-3.23V2h-2v2.096L9 0 0 9v1h2v6h5v-5h4v5h5v-6h2z" fillRule="nonzero" />
                                    </svg>
                                </div>
                                Объекты
                            </a>
                            */
                        }
                    </nav>
                    <div className="Doc_view_middle_info_balance"><div className="text"><div className="value">
                        {This.state.balance}
                    </div>
                        Баланс по договору
                    </div>
                        <button className="Doc_search_button" onClick={This.handlePayButton}>
                            <svg width="20" height="14" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 0H2C.667 0 0 .667 0 2v10c0 1.333.667 2 2 2h16c1.327 0 1.99-.667 1.99-2 .002 0 .006-3.333.01-10 0-1.333-.667-2-2-2zM9.5 4h-7c-.333 0-.5-.167-.5-.5 0-.333.167-.5.5-.5h7c.333 0 .5.167.5.5 0 .333-.167.5-.5.5zm0 3h-7c-.333 0-.5-.167-.5-.5 0-.333.167-.5.5-.5h7c.333 0 .5.167.5.5 0 .333-.167.5-.5.5zM17 9c.667 0 1 .333 1 1s-.333 1-1 1H3c-.667 0-1-.333-1-1s.333-1 1-1zm-1-6c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2z" fill="#FFF" fillRule="nonzero" />
                            </svg>
                            Оплатить
                        </button>
                    </div>
                    {contents}
                    {switchPage}
                </div>
            </div>
            {PhysUserDocs.renderDocbotInfo(This)}
        </div>
        )
    }
    static renderDocTable(data, activePage) {
        const res = [];
        let subset = data.slice(activePage * rowsPerPage, activePage * rowsPerPage + rowsPerPage)
        console.log(activePage);
        return (
            <div className="Doc_view_table">
                {/*Заглавье таблицы*/}
                <div className="Doc_view_table_header">
                    <div className="Doc_view_table_column">
                        Дата
                    </div>
                    <div className="Doc_view_table_column">
                        Начисление
                    </div>
                    <div className="Doc_view_table_column">
                        Платеж
                    </div>
                    <div>Описание</div>
                </div>
                {/*Тело таблицы*/}

                {

                    data.slice(activePage * rowsPerPage, activePage * rowsPerPage + rowsPerPage).map((d, i) =>
                        <div key={d.dt + i} className="Doc_view_table_row">
                            < div className="Doc_view_table_column" >
                                {d.dt}
                            </div >
                            <div className="Doc_view_table_column">
                                <span>
                                    {d.summ <= 0 ? '–' : d.summ + ' ₽'}
                                </span>
                            </div>
                            <div className="Doc_view_table_column">
                                {d.summ2 <= 0 ? '–' : d.summ2 + ' ₽'}
                            </div>
                            <div className="comment_row">
                                <span className="comment">
                                    {d.comment}
                                </span>
                            </div>
                        </div >)
                }
            </div>
        );
    }
    static renderDocbotInfo(This) {
        return (

            <footer className="Doc_view_bot_info">
                <div className="Doc_search_container">
                    <div className="container">
                        <div className="Doc_view_bot_contact">
                            Если возникли вопросы,&nbsp;
                            {/*  <br />*/}
                            <a href="https://sibtko.ru/svyaz" target="_blank">
                                напишите нам
                            </a>
                        </div>
                        <a href="mailto:feedback@sibtko.ru" target="_blank" className="Doc_view_bot_button">
                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.292 15.315l3.389 1.208-2.261 3.21a.616.616 0 01-.698.235.651.651 0 01-.43-.62v-4.033zM19.086.074a.603.603 0 01.651.047c.192.143.29.384.256.628l-2.291 16.087a.657.657 0 01-.294.46.608.608 0 01-.526.06l-8.736-3.115 8.59-10.8-11.101 9.905-5.212-1.859a.649.649 0 01-.421-.568.66.66 0 01.334-.628z" fill-rule="nonzero" />
                            </svg>
                            Написать письмо
                        </a>
                    </div>
                </div>
            </footer>
        )
    }
    static renderPageSwitch(instance, data, activePage) {
        if (data.length <= rowsPerPage) return (<nav className="Doc_view_pageswitch"></nav>);

        const res = [];
        const s = Math.floor((data.length - 1) / rowsPerPage);
        for (let i = 0; i <= s; i++) {
            if (activePage == i)
                res.push(<a href="#" onClick={(e) => e.preventDefault()} className="page active">{i + 1}</a>)
            else
                res.push(<a href="#" onClick={(e) => { instance.handleButClick(i, e); }} className="page">{i + 1}</a>)
        };

        return (
            <nav className="Doc_view_pageswitch">
                <a onClick={(e) => { instance.handleArrowClick(-1, e); }} href="#" className="page arrow">
                    <svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.805 5.003l2.969-3.121A.811.811 0 006 1.3a.811.811 0 00-.226-.582L5.316.238A.734.734 0 004.763 0a.735.735 0 00-.553.238L.226 4.418A.812.812 0 000 5c0 .23.075.423.226.581L4.21 9.762c.15.159.335.238.553.238.218 0 .402-.08.553-.238l.458-.481A.807.807 0 006 8.703a.83.83 0 00-.226-.585l-2.97-3.115z" fillRule="nonzero" />
                    </svg>
                </a>

                {res}

                <a onClick={(e) => { instance.handleArrowClick(1, e); }} href="#" className="page arrow">
                    <svg width="6" height="10" viewBox="0 0 6 10" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.774 4.422L1.79.244A.71.71 0 001.24 0a.71.71 0 00-.55.244L.227.725A.807.807 0 000 1.303c0 .222.075.417.226.584L3.196 5 .226 8.12A.807.807 0 000 8.696c0 .223.075.417.226.584l.464.482a.73.73 0 00.55.237c.216 0 .4-.08.55-.237l3.984-4.179A.844.844 0 006 5a.807.807 0 00-.226-.578z" fillRule="nonzero" />
                    </svg>
                </a>
            </nav>)
    }
    static renderPayPanel(This) {
        /*        const [payment, setPayment] = useState("")*/
        return (
            <div>
                <div className="Doc_view_payment_fade" />
                <div className="Doc_view_payment_window">
                    <a href="#" onClick={This.handlecancellButtonClick} className="Close_X">
                        <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.074 4.996l3.704-3.704a.756.756 0 10-1.07-1.07L5.004 3.926 1.3.222a.756.756 0 10-1.07 1.07l3.704 3.704L.222 8.708a.756.756 0 101.07 1.07l3.712-3.712L8.708 9.77a.755.755 0 001.07 0 .756.756 0 000-1.07L6.074 4.995z" fill-rule="nonzero" />
                        </svg>
                    </a>
                    <div className="title">
                        Оплата по договору
                    </div>

                    <Form Validate onSubmit={This.CompletePayButton}>
                        <div className="field">
                            <div className="title">
                                Выбранный договор
                            </div>
                            <div className="text">
                                № {This.state.Doc} от {DateString2String(This.state.DocDate)}
                            </div>
                        </div>
                        <div className="field">
                            <input type="hidden" name="sum" value="710.99" />
                            <input type="hidden" name="clientid" value="100000001" />
                            <input type="hidden" name="user_result_callback" value="http://lk.sibtko.ru/pay/" />
                            <input defaultValue={This.state.balance > 0 ? 0 : -This.state.balance} onBlur={(e) => { e.target.value = e.target.value.replace(/[^0-9\.]+/g, ''); e.target.value = parseFloat(e.target.value); if (isNaN(e.target.value)) e.target.value = 0; }} type="text" name="summ" placeholder="Сумма платежа" className="input" />
                            <label className="label">
                                Сумма платежа
                            </label>
                        </div>
                        <div className="field">
                            <InputGroup hasValidation >
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="    E-mail"
                                    className="mb-3"
                                >
                                    <Form.Control required
                                        type="email" name="email" placeholder="E-mail" className="input" onChange={(e, v) => { This.setState({ user_email: v }) }} value={This.state.user_email} isValid={PhysUserDocs.emailValidation(This.state.user_email)} />
                                    <Form.Control.Feedback type="invalid">
                                        Введите почту
                                    </Form.Control.Feedback>
                                </FloatingLabel>
                            </InputGroup>

                            <div className="hint">
                                Адрес электронной почты будет использован для отправки чека об оплате услуг и не будет передан третьим лицам.
                            </div>
                        </div>
                        <div className="field">
                            <InputGroup hasValidation >
                                <Form.Check required id="checkbox" className="Doc_view_payment_window_acceptance_checkbox_custom" type="checkbox" name="agree" label=" Я подтверждаю правильность указанных данных и хочу перейти к оплате услуг" />

                            </InputGroup>
                        </div>
                        <div className="Doc_view_payment_window_bot">

                            <button type="submit" name="pay" className="button" >
                                Перейти к оплате
                            </button>
                            <button type="submit" name="cancell" className="button_cancell" onClick={This.handlecancellButtonClick}>
                                Отменить
                            </button>
                        </div >
                    </Form >
                </div >
            </div >
        )
    }
    render() {
        //let contents = this.state.loading
        //    ? <p><em>Loading...</em></p>
        //    : FetchData.renderForecastsTable(this.state.forecasts);
        let SearchPage = !this.state.IsDocFinded ? PhysUserDocs.renderSearchPage(this) : (<div></div>);
        let DocPage = this.state.IsDocFinded ? PhysUserDocs.renderDocumentPage(this) : (<div></div>);
        let PaymentWindow = this.state.PayWindowActive ? PhysUserDocs.renderPayPanel(this) : (<div></div>)
        return (
            <div>
                {PaymentWindow}
                {SearchPage}
                {DocPage}F
            </div>

        );
    }

    async loadData() {
        fetch((process.env.REACT_APP_USE_API_URL == true ? process.env.REACT_APP_API_URL : '') + 'api/documents/search' + '/' + this.state.Doc).then(async (response) => {
            if (response.status === 200) {
                /* const response = await fetch('sber' + '?user=' + this.state.Doc+'&r=1');*/
                const data = await response.json();
                const date = data.dateFrom;
                var tmp = 0.;
                data.accrs.forEach(e => tmp += e.summ.replace(',', '.') * 1 - e.summ2.replace(',', '.') * 1);
                tmp = tmp.toFixed(2)
                tmp = Math.round(-tmp * 100, 2) / 100;


                /*alert(date);*/
                this.setState({ Dog:data, data: data.accrs, loading: false, balance: tmp, failedToLoad: false, IsDocFinded: true, DocDate: date });
            }
            else { this.setState({ failedToLoad: true, IsDocNotFined: true }); }
        }).catch(e => console.error('EXCEPTION: ', e))


    }
    async SberButton(email, summ) {
        //const respons = await fetch('sber' + '/' + this.state.Doc + '?summ=' + (this.state.balance < 0 ? -this.state.balance : 2.25));
        const respons = await fetch((process.env.REACT_APP_USE_API_URL == true ? process.env.REACT_APP_API_URL : '') + 'sber' + '/' + this.state.Doc + '?summ=' + summ + '&email=' + email +'&backEndPoint=PhysUserDocs');
        if (respons.ok) {
            const data = respons.text().then(function (text) {
                /*              console.log(text);*/
                if (text == '0') { console.error("AAAAAA"); return; }
                else
                    window.location.replace(text);
            });;

        }
    }
    async CheckPayment(email, summ) {
        //const respons = await fetch('sber' + '/' + this.state.Doc + '?summ=' + (this.state.balance < 0 ? -this.state.balance : 2.25));
        const respons = await fetch((process.env.REACT_APP_USE_API_URL == true ? process.env.REACT_APP_API_URL : '') + 'sber' + '/' + this.state.Doc + '?summ=' + summ + '&email=' + email + '&backEndPoint=PhysUserDocs');
        if (respons.ok) {
            const data = respons.text().then(function (text) {
                /*              console.log(text);*/
                if (text == '0') { console.error("AAAAAA"); return; }
                else
                    window.location.replace(text);
            });;

        }
    }
    //static redirectPost(url, data) {
    //var form = document.createElement('form');
    //document.body.appendChild(form);
    //form.method = 'post';
    //form.action = url;
    //for (var name in data) {
    //    var input = document.createElement('input');
    //    input.type = 'hidden';
    //    input.name = name;
    //    input.value = data[name];
    //    form.appendChild(input);
    //}
    //form.submit();
    //}
}
