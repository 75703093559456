import { Counter } from "./components/Counter";
import { PhysUserDocs } from "./components/PhysUserDocs";
import { UrUserDocs } from "./components/UrUserDocs";
import { Home } from "./components/Home";
import { SberPaySucces } from "./components/SberPaySucces";
import { NavMenu } from "./components/NavMenu";

const AppRoutes = [
    {
        path: '',
        element: <NavMenu />
    },
    {
        path: '/UrUserDocs',
        element: <UrUserDocs />
    },
    {
        path: '/PhysUserDocs',
        element: <PhysUserDocs />
    },
    {
        path: '/sberPayment',
        element: <SberPaySucces />
    },

];

export default AppRoutes;
