import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import './StyleSheet.scss';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
      return (
          <div className="Doc_search_page">
              <div className="Doc_search_container">
                  <div className="Doc_search_logo">
                      <img src={require('../Images/logo-inner-4.png')} alt="logo.jpg" />
                  </div>
                  <form action="#" className="Doc_search_form">
                      <ul className="navbar-nav flex-grow">

                          <NavItem className="Nav_link">
                          <NavLink tag={Link} className="text-dark main_nav_buttons" to="/urUserDocs">Юридические лица</NavLink>
                      </NavItem>
                      <NavItem className="Nav_link">
                              <NavLink tag={Link} className="text-dark main_nav_buttons" to="/PhysUserDocs">Физические лица</NavLink>
                          </NavItem>
                      </ul>
                  </form>
                  <div className="Doc_search_subtext"></div>
                  <div className="Doc_search_bot"></div>
              </div>
          </div>
    );
    }
}
